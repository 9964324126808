import { Link } from 'gatsby';
import React, { FC } from 'react';
import Layout from '../../components/layout';
import { homeLogoWithText } from '../../assets';

import './styles.scss';

const App: FC = () => {
  return (
    <Layout
      title={'Privacy Policy | Instect Content'}
      metaName={'description'}
      metaContent={'Privacy Policy | Instect Content'}
    >
      <div className="logo-wrapper">
        <Link to="/">
          <span>{homeLogoWithText}</span>
        </Link>
      </div>
      <div className="privacy">
        <section>
          <h1>Privacy Policy</h1>
          <p>
            By using the service on this site, you signify your agreement to follow all the privacy policies set forth
            on this page.
          </p>
          <h5>Data Collected</h5>
          <p>
            When using our service, our website may store your IP, country of origin, URLs, number of downloads and
            related statistics. We may also use different types of cookies to log your personal preferences and
            experiences to our service so that we can provide personalized services to you. A cookie does not imply that
            we can access your private data in your computer, and you can further change your web browser's setting to
            decline cookies.
          </p>

          <h5>Data Used</h5>
          <p>
            We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information.
            This does not include trusted third parties who assist us in operating our website, conducting our business,
            or servicing you, so long as those parties agree to keep this information confidential. We may also release
            your information when we believe release is appropriate to comply with the law, enforce our site policies,
            or protect ours or others rights, property, or safety.
          </p>

          <h5>Changes to This Privacy Policy</h5>

          <p>
            We may update our Privacy Policy from time to time. When the changes are made, we will post the revised
            Policies here.
          </p>
        </section>
      </div>
    </Layout>
  );
};

export default App;
